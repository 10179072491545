<div class="content">
  <h1>{{ 'About' | translate }}</h1>

  <p>{{ 'Kompakkt is an open source 3D viewer working towards IIIF compliance.' | translate }}</p>
  <p>{{ 'Kompakkt is being developed at:' | translate }}</p>

  <p>
    <a href="http://dh.uni-koeln.de">{{ 'Institute for Digital Humanities' | translate }}</a><br />
    {{ 'University of Cologne' | translate }}<br />
    Albertus-Magnus-Platz<br />
    50931 Köln<br /><br />
    <a href="mailto:info@kompakkt.de">info&#64;kompakkt.de</a>
  </p>

  <h2>Kompakkt {{ 'Team' | translate }}</h2>

  <p><a href="https://github.com/ZetOE"> Zoe Schubert </a></p>

  <p><a href="https://github.com/HeyItsBATMAN"> Kai Niebes </a></p>

  <p><a href="https://github.com/NadNo12"> Nadjim Noori </a></p>

  <p><a href="https://github.com/vmalieske">Vera Malieske</a></p>

  <p><a href="https://github.com/Grizzly127">Senya Bär</a></p>

  <p><a href="https://tws.phil-fak.uni-koeln.de/team/wissenschaftliche-mitarbeiterinnen/enes-tuerkoglu-ma">Enes Türkoğlu
    </a></p>

  <p><a href="https://github.com/janwieners"> Jan G. Wieners </a></p>

  <hr />

  <p>
    {{ 'In the context of' | translate }}
    <a href="https://nfdi4culture.de/">NFDI4Culture</a>, a
    <a href="https://gitlab.com/nfdi4culture/ta1-data-enrichment/semantic-kompakkt">
      {{ 'semantic version of' | translate }} Kompakkt
    </a>
    {{ 'is being developed at:' | translate }}
  </p>

  <p>
    <a href="https://www.tib.eu/de/forschung-entwicklung/forschungsgruppen-und-labs/open-science">
      {{ 'Open Science Lab' | translate }}
    </a>
    <br />
    TIB – {{ 'Leibniz Information Centre for Science and Technology' | translate }}<br />
    Lange Laube 28<br />
    30159 Hannover<br /><br />
  </p>

  <h2>{{ 'Semantic' | translate }} Kompakkt {{ 'Team' | translate }}</h2>

  <p><a href="https://github.com/ZetOE"> Zoe Schubert </a></p>

  <p><a href="https://github.com/HeyItsBATMAN"> Kai Niebes </a></p>

  <p><a href="mailto:Ina.Bluemel@tib.eu"> Ina Blümel </a></p>

  <p><a href="https://gitlab.com/luguenth1"> Lukas Günther </a></p>

  <p><a href="https://gitlab.com/lozanaross"> Lozana Rossenova </a></p>

  <p><a href="https://gitlab.com/sohmenl">Lucia Sohmen</a></p>

  <!-- <p><a href="https://github.com/richard-vock"> Richard Vock </a></p> -->
</div>
